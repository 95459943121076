import React from "react";
import Layout from "../../components/Layout";

export default () => (
	<Layout>
		<section className="section">
			<div className="container">
				<div
					className="content has-text-centered"
					style={{ marginTop: "50px" }}
				>
					<h1>Thanks for getting in touch!</h1>
				</div>
				<div style={{ height: "100%" }}></div>
			</div>
		</section>
	</Layout>
);
